<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">

        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Filter By Crop</label>
            <b-select
              v-model="filter.crop"
              :options="filterCrops"
              @change="onTableRefresh"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="onShowAddForm"
          >
            Add Crop Insecticide
          </b-button>
        </b-col>

      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onShowEditForm(row.item)"
            >
              Edit
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-crop-insecticide-form"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      @ok="onConfirmAction"
    >
      <ValidationObserver
        ref="form"
      >
        <form
          role="form"
          novalidate
        >
          <ValidationProvider
            #default="{ errors }"
            name="crop"
            vid="crop"
            rules="required"
          >
            <div class="form-group">
              <label for="crop">Crop</label>
              <b-form-select
                id="crop"
                v-model="cropInsecticide.crop"
                :options="list.crops"
                type="text"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please Select Crop --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="insecticide name"
            vid="insecticide_name"
            rules="required|max:100"
          >
            <b-form-group>
              <label for="insecticide_name">Insecticide Name</label>
              <b-input
                id="insecticide_name"
                v-model="cropInsecticide.insecticide_name"
                type="text"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                placeholder="enter insecticide name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <div
            v-if="state.editing"
            class="form-group"
          >
            <b-form-checkbox
              id="is_active"
              v-model="cropInsecticide.active"
              name="is_active"
              value="1"
              unchecked-value="0"
              :disabled="state.busy"
            >
              Is Active?
            </b-form-checkbox>
          </div>

        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, AdminCropInsecticideService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminCropInsecticides',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Crop Insecticides'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      list: {
        crops: []
      },
      filter: {
        crop: 'All'
      },
      cropInsecticide: {
        id: '',
        crop: null,
        insecticide_name: '',
        active: 0
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'crop.crop_name', label: 'crop name', sortable: true },
          { key: 'insecticide_name', sortable: true },
          { key: 'active', label: 'IS ACTIVE?' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Edit Crop Insecticide' : 'Add Crop Insecticide'
    },
    filterCrops () {
      return [{ value: 'All', text: 'All' }].concat(this.list.crops)
    }
  },

  mounted () {
    core.index()
    this.getLists()
  },

  methods: {

    async myProvider (ctx) {
      try {
        const { data } = await AdminCropInsecticideService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            filter_crop: this.filter.crop
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getLists () {
      await SharedListService.getCrops().then(({ data }) => {
        this.list.crops = data.map(({ id, crop_name }) => ({
          text: crop_name,
          value: id
        }))
      })
    },

    onTableRefresh () {
      this.$refs.table.refresh()
    },

    onShowAddForm () {
      this.state.editing = false
      this.cropInsecticide.id = 0
      this.cropInsecticide.crop = null
      this.cropInsecticide.insecticide_name = ''
      this.cropInsecticide.active = 1
      this.$bvModal.show('modal-crop-insecticide-form')
    },

    onShowEditForm (current) {
      this.state.editing = true
      this.cropInsecticide.id = current.id
      this.cropInsecticide.crop = current.crop_id
      this.cropInsecticide.insecticide_name = current.insecticide_name
      this.cropInsecticide.active = current.active
      this.$bvModal.show('modal-crop-insecticide-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          let title = 'Do you really want to create crop insecticide?'
          if (this.state.editing) {
            title = 'Do you really want to save changes?'
          }
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (this.state.editing) {
                return this.onFormPutSubmit()
              } else {
                return this.onFormPostSubmit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        AdminCropInsecticideService.post(this.cropInsecticide).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-crop-insecticide-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.onTableRefresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        AdminCropInsecticideService.put(this.cropInsecticide).then(({ data }) => {
          this.$bvModal.hide('modal-crop-insecticide-form')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.table.localItems, { id: data.cropInsecticide.id })
            row.crop = data.cropInsecticide.crop
            row.crop_id = data.cropInsecticide.crop_id
            row.insecticide_name = data.cropInsecticide.insecticide_name
            row.active = data.cropInsecticide.active
            row.updated_at = data.cropInsecticide.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    }
  }
}
</script>
